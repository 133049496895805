import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import { addDays } from "date-fns";
import {
  ChartWrapper,
  ReportWrapper,
  ChartTitle,
  Subtitle,
  DatepickerRow,
} from "./styles";
import CustomDatePicker from "./datepicker";
import { queryReport } from "./queryReport";
import { formatDateWithDay } from "./utils";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";

const MainGalleryViewsPerDay = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [average, setAverage] = useState(0);
  const [total, setTotal] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    let labels = [];
    let values = [];
    let dates = [];
    let filteredData = [];

    queryResult.map((row) => {
      if (!dates.includes(row.dimensions[0])) {
        return dates.push(row.dimensions[0]);
      }
    });
    let array = queryResult.filter((row) => {
      return row.dimensions[1].includes("Main");
    });

    dates.forEach((date) => {
      function addUp1(array) {
        let views = [];
        let filteredDate = array.filter((row) => {
          return row.dimensions[0] === date;
        });
        filteredDate.forEach((row) => {
          return views.push(parseInt(row.metrics[0].values[0]));
        });

        if (views.length == 0) {
          views.push(0);
        }

        const reducer = (acc, current) => acc + current;
        return views.reduce(reducer);
      }

      let data = {
        time: date,
        value: addUp1(array),
      };

      return filteredData.push(data);
    });

    function addUp(array) {
      let views = [];
      array.forEach((row) => {
        return views.push(parseInt(row.value));
      });
      const reducer = (acc, current) => acc + current;
      return views.reduce(reducer);
    }

    let total1 = addUp(filteredData);
    setAverage(parseInt(total1 / filteredData.length));
    setTotal(total1);

    filteredData.forEach((row) => {
      labels.push(formatDateWithDay(row.time));
      values.push(row.value);
    });

    setReportData({
      ...reportData,
      labels,
      values,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        label: `${props.title} per day`,
        fill: false,
        lineTension: 0.3,
        borderColor: "#D32424",
        // backgroundColor: "#D32424",
        pointBorderWidth: 3,
        pointHoverBackgroundColor: "#D32424",
        pointHoverBorderColor: "#D32424",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        borderWidth: 1, // Specify bar border width
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            minRotation: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 18,
      },
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(() => {
    const request = {
      viewID: props.viewID,
      startDate: props.startDate,
      endDate: props.endDate,
      metrics: "ga:pageviews",
      dimensions: ["ga:date", "ga:pagePath"],
    };
    queryReport(request)
      .then((resp) => displayResults(resp))
      .catch((error) => console.error(error));
  }, [props.startDate, props.endDate]);

  return (
    <Box py="80px" borderBottom="1px solid">
      <ReportWrapper>
        <ChartTitle>{`Main Gallery views per day (15 secs)`}</ChartTitle>
        <Subtitle>
          <Button fontSize="21px" p={5} m={5} bg="#D32424">
            {`Average - ${average}`}
          </Button>
          <Button fontSize="21px" p={5} m={5} bg="#D32424">
            {`Total - ${total}`}
          </Button>
        </Subtitle>
        {/* <DatepickerRow>
          <CustomDatePicker
            placeholder={"Start date"}
            date={startDate}
            handleDateChange={(date) => setStartDate(date)}
          />
          <CustomDatePicker
            placeholder={"End date"}
            date={endDate}
            handleDateChange={(date) => setEndDate(date)}
          />
        </DatepickerRow> */}
        {reportData && (
          <ChartWrapper>
            <Line data={data} options={options} width={100} height={250} />
          </ChartWrapper>
        )}
      </ReportWrapper>
    </Box>
  );
};

export default MainGalleryViewsPerDay;
