import {
  Button,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Link,
  Select,
  Input,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function ModalBook({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        bg={user.verified ? "#2F855A" : "grey"}
        fontSize="100%"
        onClick={onOpen}
        variant="solid">
        {user.verified ? "Approved" : "Payment Pending"}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Prospect's Payment Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr height="7" fontWeight="bold">
                    Full Name
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Email
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Form Link
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booking #
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Agent
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booked On
                  </Tr>
                </Td>
                <Td>
                  <Tr height="7">{user.name}</Tr>
                  <Tr height="7">{user.email}</Tr>
                  <Tr height="7">
                    <Link href={`${user.pdfLink}`} isExternal>
                      Download Form <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Tr>
                  <Tr height="7">{user.booking_number}</Tr>
                  <Tr height="7">{user.agent_name}</Tr>
                  <Tr height="7">{user.date}</Tr>
                </Td>
              </Tbody>
            </Table>
            <hr></hr>

            {user.verified ? (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="center" colSpan={2}>
                      Date Approved
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Td>
                    <Tr
                      width="100%"
                      d="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="12"
                      colSpan={2}>
                      {user.date_approved}
                    </Tr>
                  </Td>
                </Tbody>
              </Table>
            ) : null}
          </ModalBody>

          <ModalFooter
            d="flex"
            justifyContent="center"
            alignItems="center"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
{
  /* <div className="loading-bg"></div>
              <div className="loading-cont">
                <div className="info-cont">
                  <div className="cancel-cont">
                    <Text className="cancel" onClick={(e) => setDisInfo(false)}>
                      ✖
                    </Text>
                  </div>
                  <h5>Agent's Info</h5>
                  <Text>
                    Full Name: <b>{user.full_name}</b>
                  </Text>
                  <Text>
                    Agency: <b>{user.agency}</b>
                  </Text>
                  <Text>
                    Email: <b>{user.email}</b>
                  </Text>
                  <Text>
                    Link:
                    <a href={link} target="_blank">
                      {user.linkHtm}
                    </a>
                  </Text>
                  <Text>
                    Password: <b>{user.password}</b>
                  </Text>
                </div>
              </div> */
}
export default ModalBook;
