import { Center } from "@chakra-ui/react";
import VisitsPerDay from "Dashboard/visitsPerDay";
import { useState } from "react";

export default function AnalyticsC() {
  const [cred, setCred] = useState({
    email: "ougdevelopmentvse@gmail.com",
    // authorization: "Bearer KDnfqLJvc8oQThKcbVwCWLGSwgJzyUYAS9Uei5wz",
    authorization: "Bearer 8WFc2X_UI6EqXuWnN0RtqYZVo5a-gB8wPkNFiKhL",
    zoneTag: "fcf1026f1a2a1c4bceeef92a9cb80cce",
    key: "d7e8a9c9585102c40282ed1f7884f87814e99"
  });

  return (
    <>
      {/* <AgentsVisitsReport /> */}
      {/* <TopCountriesByRequests cred={cred} /> */}
      <VisitsPerDay cred={cred} />
      {/* <Tes cred={cred} /> */}
    </>
  );
}
