import React from "react";
import ReactDOM from "react-dom";
import ImageUploader from "react-images-upload";

export default class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
    // this.handleChange = this.handleChange.bind(this)
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles)
    });
  }

//   handleChange(event) {
//     this.setState({
//       file: URL.createObjectURL(event.target.files[0])
//     })
//   }

  render() {
    return (
      <ImageUploader
        className="imageUploadContainer"
        withIcon={true}
        withPreview={true}
        buttonText="Upload image"
        onChange={(e) => {
            this.onDrop();
            // this.handleChange();
        }}
        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
        maxFileSize={5242880}
        singleImage={true}
        result={{}}
        // withLabel={false}
      />
    );
  }
}