import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import Axios from "axios";

function ModalReject({ user, setRegistered, registered }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function notApprove(e) {
    e.preventDefault();

    async function Post() {
      await Axios.post("https://admin-api.wctland.com/disapprove", {
        email: user.email,
        password: user.password,
        full_name: user.full_name,
        agency: user.agency,
        phone: user.phone,
        status: 0,
      }).then((res) => {
        let updatedArr = [...registered];

        for (let i = 0; i < updatedArr.length; i++) {
          if (updatedArr[i].email === user.email) {
            updatedArr.splice(i, 1);
            break;
          }
        }

        setRegistered(updatedArr);
      });
    }

    Post();
  }

  return (
    <>
      <Button
        bg="#D32424"
        w="10vw"
        fontSize="sm"
        onClick={onOpen}
        mr={3}
        leftIcon={<DeleteIcon />}>
        Delete
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Confirm Deletion?
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr
                    d="flex"
                    justifyContent="center"
                    alignContent="center"
                    height="7">
                    <Button bg="#D32424" onClick={(e) => notApprove(e)}>
                      Delete
                    </Button>
                  </Tr>
                </Td>
                <Td>
                  <Tr
                    d="flex"
                    justifyContent="center"
                    alignContent="center"
                    height="7">
                    <Button onClick={onClose}>Cancel</Button>
                  </Tr>
                </Td>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter
            d="flex"
            justifyContent="center"
            alignItems="center"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
{
}
export default ModalReject;
