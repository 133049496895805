import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Center,
  Image,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Logo from "assets/wct_logo.png";

export default function ModalAnnounce({
  header,
  subheader,
  title,
  paragraph,
  uploadImage,
  withLogo,
  design
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tempUploadImage, setTempUplaodImage] = useState();

  // useEffect(() => {
  // async function getBackImage(){
  //   fetch('https://admin-api.wctland.com/getTempImage',{
  //     method: 'GET',
  //     headers:{
  //       "Content-Type": 'application/json, charset=UTF-8',
  //       'Accept': 'application/json, text/html',
  //     },
  //     credentials: 'include',
  //   })
  //   .then(data => data.json())
  //   .then((data) =>{
  //     setTempUplaodImage('https://admin-api.wctland.com/' + data.data[0].imagelink)
  //     console.log(data.data[0].imagelink)
  //     console.log(tempUploadImage)
  //   });
  // }
  // }, [])

  var binaryData = [];

  // useEffect(() => {
  //   getBackImage()
  // },[uploadImage]);

  const getBackImage = () => {
    if (uploadImage) {
      setTimeout(() => {
        var output = document.getElementById("preview");
        binaryData.push(uploadImage);
        let blob = new Blob(binaryData, { type: "image/png" });
        let url = window.URL.createObjectURL(blob);

        console.log("URL222", url);
        console.log("BLOB222", blob);
        console.log("BINARY DATA222", binaryData);
        console.log("OUTPUT222", output);

        if (url) {
          output.src = url;
          output.onload = function () {
            window.URL.revokeObjectURL(output.src); // free memory
          };
        }
      }, 1000);
    }
  };
  // }, [uploadImage]);

  return (
    <>
      <Button
        mx="10"
        onClick={() => {
          onOpen();
          getBackImage();
        }}>
        Preview
      </Button>

     {design === 'text' ? <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="100%">
          <ModalHeader>Preview Announcement</ModalHeader>
          <ModalCloseButton />
          <ModalBody h="100%" w="100%">
            <Box
              overflowY="auto"
              overflowX="hidden"
              p="5"
              px="16"
              d="flex"
              flexDir="column"
              borderRadius="md"
              bg="#2D2829"
              w="100%"
              justifyContent="center"
              alignItems="center"
              h="80%">
              <Center flexDir="column" pb="1">
                {withLogo === "with" ? (
                  <Image w="150px" src={Logo} alt="WCT" />
                ) : null
                }
                {/* <Image id="uploadPreview" w="80%" h="40%" alt="loading image.." />
                 */}
                {/* <br /> */}
                <Text color="#ffe36c" fontSize="1.3rem" fontWeight="bold">
                  {header}
                </Text>
                {/* <br /> */}
                <Text color="#ffe36c">{subheader}</Text>
              </Center>

              {/* <Text
                color="#ffe36c"
                fontWeight="bold"
                textAlign="center"
                alignSelf="center"
                textTransform="uppercase">
                {title}
              </Text> */}
              {/* <br /> */}
              {/* {tempUploadImage && (
                <Image src={tempUploadImage} w="80%" h="50%" />
              )} */}
              {/* <Box w="max-content">
                {uploadImage && (
                  <Image id="preview" w="100vw" h="fit-content" maxH="65vh" objectFit="contain"/>
                )}
              </Box>
              <br /> */}

              <Box w="35vw">
                <Text color="#ffe36c" whiteSpace="pre-wrap" textAlign="justify">
                  {paragraph}
                </Text>
              </Box>

              <Text color="#ffe36c" textAlign="end" alignSelf="flex-end">
                -WCT LAND SDN BHD
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      :
      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="100%">
        <ModalHeader>Preview Announcement</ModalHeader>
        <ModalCloseButton />
        <ModalBody h="100%" w="100%">
          <Box
            overflowY="auto"
            overflowX="hidden"
            p="5"
            px="16"
            d="flex"
            flexDir="column"
            borderRadius="md"
            bg="#2D2829"
            w="100%"
            justifyContent="center"
            alignItems="center"
            h="80%">
            <Center flexDir="column" pb="1">
              {/* {withLogo === "with" ? (
                <Image w="150px" src={Logo} alt="WCT" />
              ) : null
              } */}
              {/* <Image id="uploadPreview" w="80%" h="40%" alt="loading image.." />
               */}
              {/* <br /> */}
              {/* <Text color="#ffe36c" fontSize="1.3rem" fontWeight="bold">
                {header}
              </Text> */}
              {/* <br /> */}
              {/* <Text color="#ffe36c">{subheader}</Text> */}
            </Center>

            {/* <Text
              color="#ffe36c"
              fontWeight="bold"
              textAlign="center"
              alignSelf="center"
              textTransform="uppercase">
              {title}
            </Text> */}
            <br />
            {/* {tempUploadImage && (
              <Image src={tempUploadImage} w="80%" h="50%" />
            )} */}
            {/* <Box w="max-content"> */}
              {uploadImage && (
                <Image id="preview" maxW="960px" h="100%" alt="loading image..." objectFit="contain"/>
                // <Image id="preview" h="fit-content" objectFit="contain" maxW="100vw" maxH="90vh" />
               )}
            {/* </Box> */}
            <br />

            {/* <Box w="35vw">
              <Text color="#ffe36c" whiteSpace="pre-wrap" textAlign="justify">
                {paragraph}
              </Text>
            </Box> */}

            <Text color="#ffe36c" textAlign="end" alignSelf="flex-end">
              -WCT Holdings Berhad
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
}
    </>
  );
}
