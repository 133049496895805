import {
  Button,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Link,
  Select,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";

function ModalRejectInfo({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button color="#D32424" fontSize="100%" onClick={onOpen} variant="solid">
        Rejected
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Prospect's Payment Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr height="7" fontWeight="bold">
                    Full Name
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Email
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Payment Slip
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booking #
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Agent
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booked On
                  </Tr>
                </Td>
                <Td>
                  <Tr height="7">{user.name}</Tr>
                  <Tr height="7">{user.email}</Tr>
                  <Tr height="7">
                    <Link href={`${user.slip}`} isExternal>
                      Download Slip <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Tr>
                  <Tr height="7">{user.booking_number}</Tr>
                  <Tr height="7">{user.agent_name}</Tr>
                  <Tr height="7">{user.date}</Tr>
                </Td>
              </Tbody>
            </Table>
            <hr></hr>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Rejection Reason
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr
                    justifyContent="center"
                    d="flex"
                    alignItems="center"
                    height="12"
                    colSpan={2}>
                    ‟{user.reject_reason}” - on {user.date_rejected}
                  </Tr>
                </Td>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter
            d="flex"
            justifyContent="center"
            alignItems="center"></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
{
}
export default ModalRejectInfo;
