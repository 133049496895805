import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import { Pie } from "react-chartjs-2";
import CustomDatePicker from "./datepicker";
import { queryReport } from "./queryReport";
import { ChartTitle, PieChartWrapper, colors } from "./styles";
import { Button, Box } from "@chakra-ui/react";

const TopSourcesPieChart = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [totalSources, setTotalSources] = useState(0);

  const displayResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    setTotalSources(queryResult.length);

    let labels = [];
    let values = [];
    let totalValues = 0;
    let percentageValues = [];
    let bgColors = [];
    queryResult.forEach((row, id) => {
      labels.push(row.dimensions[0]);
      values.push(parseInt(row.metrics[0].values[0]));
      bgColors.push(colors[id]);
    });

    function totalVisits(total, num) {
      return total + num;
    }

    totalValues = values.reduce(totalVisits);

    values.forEach((value) => {
      value = (value / totalValues) * 100;
      percentageValues.push(value.toFixed(2));
    });

    console.log(percentageValues);

    setReportData({
      ...reportData,
      labels,
      percentageValues,
      colors: bgColors,
    });
  };

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        data: reportData.percentageValues,
        backgroundColor: reportData.colors,
      },
    ],
  };

  const options = {
    legend: {
      position: "bottom",
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 18,
        padding: 10,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(() => {
    const request = {
      viewID: props.viewID,
      startDate,
      endDate,
      metrics: "ga:users",
      dimensions: ["ga:source"],
    };
    setTimeout(
      () =>
        queryReport(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1500
    );
  }, [startDate, endDate]);

  return (
    <Box py="80px" borderBottom="1px solid">
      <ChartTitle>Top Sources of Visits (%)</ChartTitle>
      <Button fontSize="21px" p={5} m={5} bg="#D32424">
        {`Total sources - ${totalSources}`}
      </Button>
      <CustomDatePicker
        placeholder={"Start date"}
        date={startDate}
        handleDateChange={(date) => setStartDate(date)}
      />
      <CustomDatePicker
        placeholder={"End date"}
        date={endDate}
        handleDateChange={(date) => setEndDate(date)}
      />
      {reportData && (
        <PieChartWrapper>
          <Pie data={data} options={options} width={500} height={500} />
        </PieChartWrapper>
      )}
    </Box>
  );
};

export default TopSourcesPieChart;
