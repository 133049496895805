import {
  Button,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Link,
  Select,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { CheckIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import Axios from "axios";

function ModalBook({ user, setList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({
    bookingFee: "",
    paymentMode: "",
    chequeNo: "",
    cardNo: "",
    bankRefNo: "",
    receiptNo: "",
    cashPurchase: "",
    endFinance: "",
    solicitor: "",
    paymentMode: "",
  });

  async function acceptBook(e) {
    e.preventDefault();

    let formUrl = user.pdfLink;

    // await Axios.post("https://api.wctland.com/convert", {
    //   paymentDetails,
    //   formUrl,
    // })
    //   .then((res) => {
    //     setList(res.data);
    //     onClose();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    let userData = {
      email: user.email,
      booking_number: user.booking_number,
      agent_email: user.agent_email,
      verify: 1,
      prospect_name: user.name,
      formUrl,
      paymentDetails,
    };
    await Axios.post("https://api.wctland.com/verify", userData)
      .then((res) => {
        setList(res.data);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    let paymentModeName;

    if (paymentMode == "Approval Code") {
      paymentModeName = "Card";
    } else if (paymentMode == "Cheque No.") {
      paymentModeName = "Cheque";
    } else if (paymentMode == "Cash") {
      paymentModeName = "Cash";
    } else if (paymentMode == "Bank Ref. No.") {
      paymentModeName = "Online Transfer";
    }

    setPaymentDetails({ ...paymentDetails, paymentMode: paymentModeName });
  }, [paymentMode]);

  return (
    <>
      <Button
        fontSize="100%"
        onClick={onOpen}
        leftIcon={<CheckIcon />}
        bg="#2F855A"
        variant="solid">
        Approve
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Prospect's Payment Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr height="7" fontWeight="bold">
                    Full Name
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Email
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Form Link
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booking #
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Agent
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booked On
                  </Tr>
                </Td>
                <Td>
                  <Tr height="7">{user.name}</Tr>
                  <Tr height="7">{user.email}</Tr>
                  <Tr height="7">
                    <Link href={`${user.pdfLink}`} isExternal>
                      Download Form <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Tr>
                  <Tr height="7">{user.booking_number}</Tr>
                  <Tr height="7">{user.agent_name}</Tr>
                  <Tr height="7">{user.date}</Tr>
                </Td>
              </Tbody>
            </Table>
            <hr></hr>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Prospect's Payment Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr
                    width="130px"
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold">
                    Booking Fee
                  </Tr>
                  <Tr
                    width="130px"
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold">
                    Payment Mode
                  </Tr>
                  {paymentMode == "" ? null : (
                    <Tr
                      width="130px"
                      d="flex"
                      alignItems="center"
                      height="12"
                      fontWeight="bold">
                      {paymentMode}
                    </Tr>
                  )}
                  <Tr
                    width="130px"
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold">
                    WCT Receipt No.
                  </Tr>
                  <Tr
                    width="130px"
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold">
                    End-Financier
                  </Tr>
                  <Tr
                    width="130px"
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold">
                    Solicitor
                  </Tr>
                </Td>
                <Td>
                  <Tr width="130px" height="12">
                    <InputGroup>
                      <InputLeftAddon children="RM" />
                      <Input
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            bookingFee: e.target.value,
                          })
                        }
                        type="text"
                      />
                    </InputGroup>
                  </Tr>
                  <Tr width="130px" height="12">
                    {
                      <Select
                        fontSize="12px"
                        placeholder="Payment Mode"
                        onChange={(e) => setPaymentMode(e.target.value)}>
                        <option value="Cash">Cash</option>
                        <option value="Cheque No.">Cheque</option>
                        <option value="Approval Code">Card</option>
                        <option value="Bank Ref. No.">Online Transfer</option>
                      </Select>
                    }
                  </Tr>
                  {paymentMode == "" ? null : (
                    <Tr width="130px" height="12">
                      {paymentMode == "Cash" ? (
                        <Input
                          onChange={(e) =>
                            setPaymentDetails({
                              ...paymentDetails,
                              cashPurchase: e.target.value,
                            })
                          }
                          type="text"
                        />
                      ) : null}
                      {paymentMode == "Cheque No." ? (
                        <Input
                          onChange={(e) =>
                            setPaymentDetails({
                              ...paymentDetails,
                              chequeNo: e.target.value,
                            })
                          }
                          type="text"
                        />
                      ) : null}
                      {paymentMode == "Approval Code" ? (
                        <Input
                          onChange={(e) =>
                            setPaymentDetails({
                              ...paymentDetails,
                              cardNo: e.target.value,
                            })
                          }
                          type="text"
                        />
                      ) : null}
                      {paymentMode == "Bank Ref. No." ? (
                        <Input
                          onChange={(e) =>
                            setPaymentDetails({
                              ...paymentDetails,
                              bankRefNo: e.target.value,
                            })
                          }
                          type="text"
                        />
                      ) : null}
                    </Tr>
                  )}
                  <Tr width="130px" height="12">
                    {
                      <Input
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            receiptNo: e.target.value,
                          })
                        }
                        type="text"
                      />
                    }
                  </Tr>
                  <Tr width="130px" height="12">
                    {
                      <Input
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            endFinance: e.target.value,
                          })
                        }
                        type="text"
                      />
                    }
                  </Tr>
                  <Tr width="130px" height="12">
                    {
                      <Input
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            solicitor: e.target.value,
                          })
                        }
                        type="text"
                      />
                    }
                  </Tr>
                </Td>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter d="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={(e) => acceptBook(e)}
              leftIcon={<CheckIcon />}
              bg="#2F855A"
              variant="solid">
              Finalize Approval
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalBook;
