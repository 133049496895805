import {
  Button,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Link,
} from "@chakra-ui/react";
import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";

function ModalChakra({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        mx="0.8vw"
        aria-label="Info Icon"
        icon={<InfoOutlineIcon />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.full_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Agent's Info
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr height="7" fontWeight="bold">
                    Full Name
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Agency
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Email
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Link
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Password
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Page Views
                  </Tr>
                </Td>
                <Td>
                  <Tr height="7">{user.full_name}</Tr>
                  <Tr height="7">{user.agency}</Tr>
                  <Tr height="7">{user.email}</Tr>
                  <Tr height="7">
                    <Link
                      href={`https://vgallery.wctland.com/${user.linkHtm}.htm`}
                      isExternal>
                      {user.linkHtm} <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Tr>
                  <Tr height="7">{user.password}</Tr>
                  <Tr height="7">{user.pageViews}</Tr>
                </Td>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
{
  /* <div className="loading-bg"></div>
          <div className="loading-cont">
            <div className="info-cont">
              <div className="cancel-cont">
                <Text className="cancel" onClick={(e) => setDisInfo(false)}>
                  ✖
                </Text>
              </div>
              <h5>Agent's Info</h5>
              <Text>
                Full Name: <b>{user.full_name}</b>
              </Text>
              <Text>
                Agency: <b>{user.agency}</b>
              </Text>
              <Text>
                Email: <b>{user.email}</b>
              </Text>
              <Text>
                Link:
                <a href={link} target="_blank">
                  {user.linkHtm}
                </a>
              </Text>
              <Text>
                Password: <b>{user.password}</b>
              </Text>
            </div>
          </div> */
}
export default ModalChakra;
