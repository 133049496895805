import {useEffect} from 'react'
import {useHistory} from "react-router-dom";


export default function Home({loggedIn, setLoggedIn}) {

    let history = useHistory()


    useEffect(() => {

        if (!loggedIn) {
          history.push('/login')
        } 
    
        if (loggedIn) {
          history.push('/dashboard')
        }
      }, [])

    return(<div>Home</div>)
}