import React, { useState, useEffect } from "react";
import ArrayToExcelButton from "./ArrayToExcelButton";

function ArrayToExcelMain({ registered }) {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchJsonArray = async () => {
    try {
      setUserData(registered);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJsonArray();
  }, [registered]);

  return (
    <div className="button-chakra">
      {loading ? (
        <p>Loading</p>
      ) : (
        <>
          <ArrayToExcelButton
            apiArray={userData}
            fileName={"UserData.xls"}
            buttonTitle={"List"}
          />
        </>
      )}
    </div>
  );
}

export default ArrayToExcelMain;
