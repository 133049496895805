import { Center } from "@chakra-ui/react";
import Dashboard from "Dashboard/dashboard";
import { useState } from "react";
import {css} from './dashboard.css'

export default function Analytics() {

  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   div.className = "dashboard";
  //   return () => {
     
  //   }
  // }, [isLoaded])

  return (
    <Center>
    <div className="dashboard">
      <h1 style={{ fontSize: "24px" }}>Analytics</h1>
      <Dashboard />
    </div></Center>
  );
}
