import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "axios";
import { renderButton, checkSignedIn } from "../utils";
import logo from "assets/wct_logo.png";
import rotate from "assets/rotate.png";
import { 
  Alert,
  AlertIcon,
  AlertDescription,
  Image,
  CloseButton
}from "@chakra-ui/react";

export default function Login({ setLoggedIn }) {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  let history = useHistory();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [alertBox, setCloseAlertBox] = useState(true)


  const updateSignin = (signedIn) => {
    //(3)
    setIsSignedIn(signedIn);
    renderButton();
  };

  const init = () => {
    //(2)
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.gapi.load("auth2", init); //(1)
  });

  function handleRefresh() {
    let loginStatus = document.getElementById("signin-button").innerText;

    if (loginStatus === "Signed in with Google") {
      setLoggedIn(true);
      history.push("/");
    }
  }

  useEffect(() => {
    const interval_id = setInterval(handleRefresh, 1000);
    return () => {
      // Stop the interval when the component unmounts.
      // Otherwise it will keeep going and you will get an error.
      clearInterval(interval_id);
    };
  }, []); // empty array of dependencies will cause it to run on mount and unmount only

  // function handleSubmit(e) {
  //     e.preventDefault();
  //     if (user && password && !user.includes(" ") && !password.includes(" ")) {
  //         Axios.post('https://admin-api.wctland.com/admin-login', {
  //             user: user,
  //             password: password
  //         }).then((res) => {
  //             console.log(res)
  //             setMessage(res.data.message)
  //             if (!res.data.error) {
  //                 setLoggedIn(true)
  //                 history.push('/')
  //             }
  //         })

  //     } else {return console.log("Invalid Login")}

  // }

  return (
    <div className="form-container">

    {/* {alertBox 
    ? <Alert w="100%" h="100vh" justifyContent="center" status="warning" flexDirection="column">
    <Image w="10%" h="10%" src={rotate}></Image>
    <AlertDescription fontSize="lg">Please rotate your device for better veiwing experience.</AlertDescription>
    <CloseButton size="lg" position="absolute" right="10px" top="10px" onClick={()=>{setCloseAlertBox(false)}}/>
    </Alert>
    : null
    } */}

      <img
        style={{ marginBottom: "50px", marginTop: "100px" }}
        className="logo"
        alt="logo"
        src={logo}
      />
      <h2 style={{ marginBottom: "50px", fontSize: "1vw" }}>Admin Log In</h2>

      <form>
        <div id="signin-button"></div>
      </form>

      {/* <form onSubmit={e => handleSubmit(e)}>
            <div className="inputContainer">
                <label className="inputTitle"><p>Username</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={user} onChange={e => setUser(e.target.value)}/>
            </div>
            <div className="inputContainer">
                <label className="inputTitle"><p>Password</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>
            
            <p>{message}</p>

            <button className="submit">Login</button>
            </form> */}
    </div>
  );
}
