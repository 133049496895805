import React, { useEffect } from "react";
import { addDays } from "date-fns";
import { queryReport } from "./queryReport";

function AgentsVisitsReport(props) {
  // starting from 1825 days ago
  const startDate = addDays(new Date(), -1825);
  const endDate = new Date();
  let agentsData = [];

  const displayResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;

    queryResult.forEach((row) => {
      if (checkAgent(row)) {
        let agentHtm = row.dimensions[0].replace(/[^\w\s!?]/g, "");
        row.dimensions[0] = agentHtm.replace("htm", "");
        return agentsData.push(row);
      }
    });

    function checkAgent(row) {
      let status = true;
      if (
        row.dimensions[0].includes("html") ||
        row.dimensions[0].includes("index") ||
        row.dimensions[0].includes("dashboard") ||
        row.dimensions[0].includes("proxy") ||
        row.dimensions[0].includes("gerard") ||
        row.dimensions[0].includes("debug") ||
        row.dimensions[0].includes("/?fb") ||
        row.dimensions[0] == "/analytics" ||
        row.dimensions[0] == "/"
      ) {
        status = false;
      }

      return status;
    }
    props.setAgentsPageViews(agentsData);
  };

  useEffect(() => {
    const request = {
      viewID: "236788878",
      startDate,
      endDate,
      metrics: "ga:pageviews",
      dimensions: ["ga:pagePath"],
      orderBy: {
        fieldName: "ga:pageViews",
        order: "DESCENDING",
      },
      filter: "ga:pagePath!@localhost/",
    };
    setTimeout(
      () =>
        queryReport(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1000
    );
  }, []);

  return <div style={{ display: "none" }}>Agents Page views</div>;
}

export default AgentsVisitsReport;
