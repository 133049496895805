import React from "react";
import { Box, Text } from "@chakra-ui/react";
import logo from "assets/wct_logo.png";
export default function Logo(props) {
  return (
    <Box {...props}>
      <Text fontSize="lg" fontWeight="bold">
        <div className="logo-cont">
          <img className="logo" alt="logo" src={logo} />
          <p>2.2</p>
        </div>
      </Text>
    </Box>
  );
}
