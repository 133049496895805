import { useEffect, useState, useRef } from "react";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Image,
  TableCaption,
  Textarea,
  Input,
  Center,
  Link,
} from "@chakra-ui/react";
import "draft-js/dist/Draft.css";
import ModalAnnounce from "Components/ModalAnnounce";
import Axios from "axios";
import ModalConfirmation from "Components/ModalConfirmation";
import UploadImage from "./UploadImage";
import ImageUploader from "react-images-upload";

export default function Announcement() {
  const messageDialogRef = useRef();

  const [header, setHeader] = useState("");
  const [subheader, setSubheader] = useState("");
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [uploadImage, setUploadImage] = useState();
  const [test, setTest] = useState();
  const [tempUploadImage, setTempUploadImage] = useState();
  const [confirmedFile, setConfirmedFile] = useState();

  // Error or success message
  const [message, setMessage] = useState("");
  const [logo, setLogo] = useState("with");
  const [design, setDesign] = useState("text");

  useEffect(() => {
    getAnnouncement();
    // getBackImage();
  }, []);

  async function getAnnouncement() {
    // await Axios.get("https://admin-api.wctland.com/announcement")
    await Axios.get("https://admin-api.wctland.com/announcement")
      .then((res) => {
        setUploadImage(
          "https://admin-api.wctland.com/" + res.data[0].imagelink
          // res.data[0].imagelink
        );
        setHeader(res.data[0].announcement);
        setSubheader(res.data[0].subtitle);
        // setTitle(res.data[0].title);
        setParagraph(res.data[0].paragraph);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function sendAnnouncement(e) {
    // e.preventDefault();
    let announceData = {
      announcement: header,
      subtitle: subheader,
      // title,
      paragraph,
      logo: logo,
      design: design,
    };

    await Axios.post("https://admin-api.wctland.com/announce", announceData)
      .then((res) => {
        console.log(res.data);
        setMessage(res.data.message);
        messageDialogRef.current.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const imageHandler = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    console.log(formData);
    setConfirmedFile(file);

    fetch("https://admin-api.wctland.com/temp", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "multipart/form-data",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => setTest(res.msg));
    // .catch((error) => {
    //   console.log(error)
    // })

    setTempUploadImage(file);
    // console.log(file);
  };


  // useEffect(() => {
  async function getBackImage() {
    fetch("https://admin-api.wctland.com/getTempImage", {
      method: "GET",
      headers: {
        "Content-Type": "application/json, charset=UTF-8",
        Accept: "application/json, text/html",
      },
      credentials: "include",
    })
      .then((data) => data.json())
      .then((data) => {
        setTempUploadImage(
          "https://admin-api.wctland.com/" + data.data[0].imagelink
          // data.data[0].imagelink
        );
        console.log(data.data[0].imagelink);
        console.log(tempUploadImage);
      });
  }
  // }, [])

  const uploadImageToBackend = (e) => {
    const file = confirmedFile;
    const formData = new FormData();
    formData.append("image", file);

    fetch("https://admin-api.wctland.com/uploadPost", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "multipart/form-data",
      },
      credentials: "include",
    }).then((res) => res.json());
    // .then((res) => setMessage(res.msg)); messageDialogRef.current.click();
  };

  var binaryData = [];
  useEffect(() => {
    if (tempUploadImage) {
      var output = document.getElementById("uploadPreview");
      binaryData.push(tempUploadImage);
      let blob = new Blob(binaryData, { type: "image/png" });
      if (blob.size > 5242880) {
        alert("Please upload a file with less than 5mb.");
      } else {
        let url = window.URL.createObjectURL(blob);

        console.log("URL", url);
        console.log("BLOB", blob);
        console.log("BINARY DATA", binaryData);
        console.log("OUTPUT", output);

        if (url) {
          output.src = url;
          output.onload = function () {
            window.URL.revokeObjectURL(output.src); // free memory
          };
          setTest(url);
        }
      }
    }
  }, [tempUploadImage]);

  return (
    <Center>
      <Table w={["50%"]} variant="simple">
        <TableCaption>
          <ModalAnnounce
            design={design}
            withLogo={logo}
            uploadImage={tempUploadImage}
            header={header}
            subheader={subheader}
            title={title}
            paragraph={paragraph}
          />
          {design === "image" ? (
            <Button
              onClick={() => {
                sendAnnouncement();
                uploadImageToBackend();
              }}
              color="white"
              bg="#D32424"
              mx="10">
              Post
            </Button>
          ) : (
            <Button
              onClick={() => {
                sendAnnouncement();
              }}
              color="white"
              bg="#D32424"
              mx="10">
              Post
            </Button>
          )}

          <ModalConfirmation
            message={message}
            messageDialogRef={messageDialogRef}
          />
        </TableCaption>

        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Input</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Content</Td>
            <Td>
              <RadioGroup onChange={setDesign} value={design}>
                <Stack direction="row" gridColumnGap={5}>
                  <Radio value="text">Text</Radio>
                  <Radio value="image">Image</Radio>
                </Stack>
              </RadioGroup>
            </Td>
          </Tr>

          {design === "image" ? (
            <Tr>
              <Td>Image (not more than 5mb | Best fit: 960px x 620px)</Td>
              <Td>
                <input
                  id="uploadImage"
                  type="file"
                  name="image"
                  accept="image/*"
                  multiple={false}
                  required
                  onChange={(e) => {
                    imageHandler(e);
                  }}
                />
                {/* <Image id="uploadPreview" w="40%" h="40%" alt="loading image.." /> */}
                {tempUploadImage && (
                  <Image
                    id="uploadPreview"
                    alt="loading image..."
                    w="40%"
                    h="40%"
                  />
                )}
              </Td>
            </Tr>
          ) : (
            <>
              <Tr>
                <Td>Logo</Td>
                <Td>
                  <RadioGroup onChange={setLogo} value={logo}>
                    <Stack direction="row" gridColumnGap={5}>
                      <Radio value="with">With Logo</Radio>
                      <Radio value="without">Without Logo</Radio>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
              <Tr>
                <Td>Header</Td>
                <Td>
                  <Input
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Sub-header</Td>
                <Td>
                  <Input
                    value={subheader}
                    onChange={(e) => setSubheader(e.target.value)}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Paragraph</Td>
                <Td>
                  <Textarea
                    whiteSpace="pre-wrap"
                    rows="16"
                    resize="none"
                    value={paragraph}
                    onChange={(e) => setParagraph(e.target.value)}
                  />
                </Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    </Center>
  );
}
